.contactus{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    background-color: #fff;
}

.videoContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
  
  .video{
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .CatchyHeadline {
    position: relative;
    width: 100%;
    height: fit-content;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 15em 0 10em 0;
  }
  
  .taglineCont {
    position: relative;
    width: 100%;
    height: fit-content;
    color: white;
    justify-content: center;
    align-items: center;
    max-width: 1900px;
    margin: 0 auto;
  }

  .Tagline {
    position: relative;
    width: 90%;
    margin-left: 10%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(2rem + 1vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: start;
    z-index: 2;
    margin-right: 20%;
  }
  

.contactus_con{
    position: relative;
    width: 100%;
    max-width: 1900px;
    margin: 0 auto !important;
    height: fit-content;
    justify-content: center;
    align-items: center; 
}

.contactus_con img{
    width: 100%;
    z-index: 100;
    top: -100px;
}

.content{
    width: 88%;
    margin-left: 6%;
}

.form{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    column-gap: 4%;
    padding-top: 4%;
}

.formleft{
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
}

.formleft label{
    text-align: start;
    padding-left: 1%;
    margin-top: 3%;
    margin-bottom: 0.3%;
    font-weight: bold;
}

.formleft input{
    border-radius: 10px;
    height: 4vh;
    border: 1px solid #EB4138;
    text-align: start;
    font-size: large;
    padding-left: 2%;
}


.formleft input:focus{
    outline: none;
}

.formleft textarea{
    height: 29.8vh;
    border-radius: 10px;
    border: 1px solid #EB4138;
    font-size: larger;
    padding-left: 2%;
    padding-top: 1%;
    margin-bottom: 2em;
}


.formleft textarea:focus{
    outline: none;
}

.formright{
    display: flex;
    flex-direction: column;
}

.formright label{
    text-align: start;
    padding-left: 3%;
    margin-top: 3%;
    margin-bottom: 0.3%;
}

.button{
    position: relative;
    width: 100%;
    justify-content: start;
    align-items: flex-start;
    padding: 5% 0;
}


.btn{
    width: fit-content;
    display: flex;
    flex-direction: row;
    padding: 2% 5% 1.5% 5%;
    background-color: #EB4138;
    border-radius: 10px;
    border: 1px solid #EB4138;
    justify-self: flex-start; 
    align-self: flex-start;
    margin-right: 100%;
    color: #fff;
    font-size: calc(0.8rem + 0.3vw);
}

.socials{
    display: flex;
    flex-direction: column;
    width: 85%;
    padding-bottom: 10%;
    padding-top: 2%;
    text-align: start;
}


.socials h1{
    font-family: Raleway;
font-size: calc(1rem + 1vw);
font-weight: 600;
letter-spacing: 0em;
margin-bottom: 5%;
}

.socials p{
    font-family: Raleway;
font-size: calc(0.7rem + 0.4vw);
font-weight: 600;
letter-spacing: 0em;
}

.icons{
    justify-self: flex-start;
    align-self: flex-start;
    text-align: start;
    margin-top: 10%;
}


.icons img{
    width: 10% !important ;
}
.links{
    display: flex;
    flex-direction: row;
    column-gap: 8%;
    margin-top: 12%;
}

.links img{
    cursor: pointer;
}


.links img:hover{
    box-shadow: 1px 1px 10px 1px #000;
    border-radius: 0.3em;
}

.contact_option_error {
    color: red;
    align-self: flex-start;
  }

@media screen and (max-width: 950px) {
    .form{
        grid-template-columns: 1fr;
    }
    
    .formright textarea{
        height: 15vh;
    }
}