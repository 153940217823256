.container{
    background-image: url('../../../assets/sidebar-bg.png');
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 300px !important;
}

.text{
    color: #fff;
    text-align: center;
}

.logo{
    width: 60%;
    justify-content: center;
    margin-bottom: 20%;
}

button {
    background: #eb4138;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: center !important;
    align-self: center;
    padding: 10px 20px 8px 20px;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.4vw);
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
  }