.TestimonialsContainer {
    position: relative;
    width: 88%;
    margin-left: 6%;
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
    max-width: 1900px;
    margin: 0 auto;
}

.heading {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5% 0 0 0;
}

.header{
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: row;
    font-family: Raleway;
    font-size: calc(0.7rem + 1.2vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #1D212D;
   
}
.subTitle{
    width: 50%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.9vw);
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 1.5%;
    color: #1D212D;
}

.contantContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 48.25% 48.25%;
    gap: 3.5vw;
}

.swiper{
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    justify-content: center;
    text-align: center;
    align-items: center;
    /* padding: 20% 0; */
}
.slide{
    position: relative;
    width: 100%;
    height: auto;
    background-color: transparent;
}

.arrowDesktop{
  display: flex ;
}
.testttt{
    position: relative;
    width: 2.5em;
    height: 2.5em;
    border: none;
    border-radius: 0.3em;
    background: #EB4138;
    color: white;
    cursor: pointer;
    transition: background-color 0.5s ease, color 1s ease;
}
.testttt:hover {
    background: white;
    color: #EB4138;
    
  }

.clientsCard{
    width: 100%;
    height: 90%;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 5%;
}

.cardContant{
    width: 84%;
    height: 100%;
    font-family: Raleway;
    margin: 0 8%; 
    padding: 4vh 0;
    display: flex;
    flex-direction: column;
    font-size: calc(0.5rem + 0.7vw);
}

.description{
    position: relative;
    width: 100%;
    text-align: start;
    font-family: Raleway;
    padding-bottom: 4vh;
    font-size: calc(0.5rem + 0.7vw);
}

.name{
    position: relative;
    width: 60%;
    display: block;
    font-family: Raleway;
    text-align: start;
}


@media screen and (max-width: 650px) {
    .heading {
        display: flex;
        flex-direction: column;
    }
    
.header{
    width: fit-content;
    align-self: center;
    text-align: center;
    align-items: center;
    padding-left: 0%;
    color: #EB4138;
}

.subTitle{
    width: 80%;
    align-self: center;
    text-align: center;
    margin: 0;
    padding: 0;
}
.clientsCard{
    width: 100%;
    height: fit-content;
    background-color: white;
    border-radius: 20px;
    margin: 10% 0;
    padding: 0;
}
.swiper{
    margin-top: 1%;
}
}

@media screen and (max-width: 950px) {
    .slide{
        height: 100% !important;
        background-color: #EB4138;
    }
    
}

@media screen and (min-width: 1400px) {
    .header{
        font-size: calc(1.5rem + 0.3vw);
    }
    .subTitle{
        font-size: calc(1.3rem + 0.2vw);
    }
    .cardContant{
        font-size: calc(1rem + 0.2vw);
    }
    .description{
        font-size: calc(1rem + 0.2vw);
    }
}