.expertise {
    position: relative;
    width: 100%;
    margin: 0 auto;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    color: #000;
    height: fit-content;
    padding: 5% 0 5% 0;
    border-radius: 10px;
  }
  
  .expertise_con {
    position: relative;
    width: 100%;
   
    height: fit-content;
    justify-content: center;
    align-items: center; 
  }
  
  .headerCont{
    width: 100%;
    display: flex;
    padding: 3% 0;
    background: #1d212d;
  }
  .header {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    text-align: center;
    align-items: center;
    color: #fff; 
    max-width: 1900px;
    margin: 0 auto;
    
  }
  
  .header h3 {
    font-family: Raleway;
    font-size: calc(1.5rem + 1.5vw);
    font-weight: 600;
    letter-spacing: 0em;
  }
  
  .header p {
      width: 95%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.8rem + 0.5vw);
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  
  }
  
  .mobileBG{
    width: 100%;
    height: 9em;
    position: absolute;
    top: 0;
    margin-top: -2%;
    background-color: #1d212d;
    display: none;
  }

  .swiper {
    position: relative;
    width: 94%;
    flex: 1;
    cursor: url(../../../assets/pointer.png), auto;
    margin: 0 auto;
    padding: 5% 0 0 0; 
    max-width: 1900px;
    margin: 0 auto;
  }
  
  .cardtext {
    position: relative;
    color: #000;
    height: fit-content;
    text-align: start;
    padding: 0 5%;
    font-family: Raleway;
  }
  
  .cardtext h3 {
    font-family: Raleway;
    font-size: calc(0.5rem + 0.7vw);
    font-weight: 600;
    text-align: start;
  }
  
  .cardtext p{
      font-family: Raleway;
      font-size: calc(0.5rem + 0.7vw);
      font-weight: 500;
      text-align: start;
  }

  .btncon{
    display: grid;
    grid-template-columns: 40% 60%;
    padding-bottom: 5%;
    padding-right: 0.5em;
  }
  
  .PrimaryCTAButton {
    position: relative;
    width: 100%;
    text-align: center;
    align-self: center;
    justify-content: center;
    display: flex;
  }
  

  .PrimaryCTAButton p{
    height: fit-content;
    align-self: center;
    cursor: pointer;
  }
  .image_height{
    height: 20vw;
  }
  
@media screen and (max-width: 950px) {
  
  .header{
    grid-template-columns: 1fr;
  }
  .mobileBG{
    display: none;
  }
  .header p {
  text-align: center;
  margin: 0 auto; 
}
.PrimaryCTAButton p{
  font-size: calc(0.5rem + 0.2vw);
}
  .image_height{
    height: 30vw;
  }
}

@media screen and (min-width:1900px) {
  
  .header h3 {
    font-size: calc(2rem + 0.5vw);
  }
  
  .header p {
    font-size: calc(0.8rem + 0.5vw);
  }
}

@media screen and (max-width:650px) {

  .image_height{
    height: 40vw;
  }
  
}

@media screen and (max-width:300px) {
.btncon{
  grid-template-columns: 1fr;
  padding:0.5em 0.5em;
}

}