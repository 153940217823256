.servicesContainer{
    position: relative;
    width: 88%;
    margin-left: 6%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
    max-width: 1900px;
    margin: 0 auto;
}

.heading {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5% 0;
}

.header{
    position: relative;
    width: 50%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    font-family: Raleway;
    font-size: calc(0.7rem + 1.2vw);
    font-weight: 600;
    line-height: 0;
    letter-spacing: 0em;
    text-align: center;
    color: #EB4138;
}
.header h3{
    margin: 0;
    margin-top: 10%;
    padding-left: 10px;
}
.logoo {
    width: fit-content ;
    height: calc(0.7rem + 1.3vw);
    align-self: center;
    padding-left: 2px;
    margin-top: 7.5%;
    display: flex;
}
.logoo img{
    position: relative;
    width: auto;
    height: 100%;
    margin: 0;
    padding: 0;
}
.subTitle{
    width: 50%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.9vw);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 1.5%;
    color: #1D212D;
    padding-left: 5%;

}

.contantContainer{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3,2fr);
    gap: 3.5%;
}

.ServicesDescription {
    position: relative;
    display: flex;
    width: 100%;
    text-align: start;
    font-size: calc(0.5rem + 0.7vw);
    justify-content: center;
    line-height: 170%;
    /* padding-top: 5%; */
}
.ServicesDescription p,
.ServicesDescription h3{
    width: 94%;
    margin-left: 3%;
}

.servicesCard {
    position: relative;
    width: 100%;
    background-color: white;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: calc(0.5rem + 0.7vw);
    line-height: 140%;
    padding-bottom: 8%;
}

.serviceImage {
    position: relative;
    height: 4em;
    width: fit-content;
    margin: 0 auto;
    padding: 8% 0 4% 0;
    /* display: none; */
}
.swiper{
    display: none;
}

@media screen and (max-width: 950px) {
    .contantContainer{
        display: grid;
        grid-template-columns: repeat(2,2fr);
        gap: 3.5vw;
    }
}

@media screen and (max-width: 650px) {
    .servicesContainer{
        width: 100%;
        margin-left: 0%;
        display: flex;
        flex-direction: column;
        padding-bottom: 10%;
    }
    .heading {
        width: 88%;
        margin-left: 6%;
       
        display: flex;
        flex-direction: column;
    }
    
.header{
    width: fit-content;
    align-self: center;
    text-align: center;
    align-items: center;
}

.subTitle{
    width: 80%;
    align-self: center;
    text-align: center;
}
    .contantContainer{
        display: none;
    }
    .swiper{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
        align-items: center;
        height: 100%;
    }
    .servicesCard {
        height: 100%;
    }
}

@media screen and (min-width: 1400px) {
    .header{
        font-size: calc(1.5rem + 0.3vw);
    }
    .subTitle{
        font-size: calc(1.3rem + 0.2vw);
    }
    .ServicesDescription {
        font-size: calc(1rem + 0.2vw);
    }
    .servicesCard {
        font-size: calc(1rem + 0.2vw);
    }
}