.briefintro{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}

.video{
    position: relative;
    height: 50vh;
    object-fit: cover;
}

.CatchyHeadline {
    position: absolute;
    width: 80%;
    margin-left: 10%;
    height: fit-content;
    display: grid;grid-template-columns: repeat(2, 50%);
    /* padding: 50vh 0 0 10vw; */
    color: white;
    justify-content: center;
    align-items: center;
    top: 40%;
  }

  .Tagline {
    position: relative;
    width: 100%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1rem + 1vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: start;
  }

  .description{
    text-align: start;

  }

.briefintro_con{
    position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center; 
}

.headerCont {
  width: 100%;
  padding: 3% 0;
  background: #1d212d;
  color: #fff;
}
.header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  text-align: center;
  align-items: center;
  color: #fff;
  max-width: 1900px;
  margin: 0 auto;
}
  
  .header h3 {
    font-family: Raleway;
    font-size: calc(1.5rem + 1.5vw);
    font-weight: 600;
    letter-spacing: 0em;
  }
  
  .header p {
      width: 85%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.8rem + 0.5vw);
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  
  }

.devscroll_con {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    overflow: hidden;
    max-width: 1900px;
    margin: 0 auto;
  }
  
  .dev_content {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: start;
    font-family: Metropolis;
    font-size: calc(0.5rem + 0.5vw);
    font-weight: 400;
    letter-spacing: 0em;
  }
  
  .image_con {
    background-color: blueviolet;
    justify-content: center;
  }
  
  .description_con {
    width: fit-content;
    height: 30vh;
    margin-left: 10%;
    font-family: Metropolis;
    font-size: calc(0.5rem + 0.5vw);
    font-weight: 400;
    letter-spacing: 0em;
    justify-content: center;
    align-self: center;
  }
  
  .service {
    /* padding: 5vh 0 5vh 0; */
    width: fit-content;
    height: fit-content;
    text-align: start;
  }
  
  
  .panel {
      width: 100%;
      position: absolute;
      text-align: center;
      overflow: hidden;
  }
  
  .panelspan {
    position: relative;
    width: 70%;
    margin-left: 15%;
    display: block;
    top: 50%;
    padding-top: 20%;
    color: #000;
    text-align: start;
  }

  .panelspan h3{
    font-family: Raleway;
    font-size: calc(1.5rem + 0.4vw);
    font-weight: 600;
    letter-spacing: 0em;
  }

  .imgCont {
    position: relative;
    display: block;
    padding-top: 10%;
  }
  
  .description {
    font-family: Raleway;
    font-size: calc(0.8rem + 0.4vw);
    font-weight: 400;
    letter-spacing: 0em;
  
  }
  
  
  .pinContainerpanel {
    height: 30vh;
    width: 100%;
    position: absolute;
    text-align: center;
  }
  
  .testt {
    width: 100%;
    height: fit-content;
    /* background: linear-gradient(147.42deg, #121D85 3.51%, #000000 192.93%); */
  
  }
  
  .mobile{
    width: 100%;
    display: none;
    flex-direction: column;
  }
  
  .mobiletext{
    width: 80%;
    margin-left: 10%;
    height: fit-content;
    justify-content: center;
    color: #000;
    text-align: start; 
    padding-bottom: 10%;
    padding-top: 1%;
    font-size: calc(0.9rem + 0.2vw);
  }
  
  @media screen and (max-width: 950px) {
    .devservices_con{
      display: none;
    }

    .devscroll_con{
      display: none;
    }
    
  .mobile{
    display: flex;
  }
  .header{
    grid-template-columns: 1fr;
  }
  
  .header p {
  text-align: center;
  margin: 0 auto; 
  padding-bottom: 5%;
}
  }