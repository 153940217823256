.footer{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    background-color: #1D212D;
}
.footer_con{
    position: relative;
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 5% 0 4% 0;
}

.topcontent{
    width: 80%;
    height: fit-content;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.logo_con{
    width: 50%;
    margin: 8% 0;
    justify-content: flex-start;
    text-align: start;
    align-items: center;
}

.routing{
    width: 100%;
    display: grid;
    grid-template-columns: 44% 25% 25%;
    color: #fff;
    text-align: start;
    column-gap: 4%;
}

.desc{
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.desc p {
    width: 70%;
    margin-bottom: 10%;
    font-size: calc(0.7rem + 0.4vw);
    font-family: Raleway;
font-weight: 400;
letter-spacing: 0em;
}

.title{
    width: fit-content;

}

.options{
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.options h2{
    margin-bottom: 7%;
    font-family: Raleway;
    font-size: calc(0.9rem + 0.2vw);
}

.loc{
    align-items: flex-end;
    justify-content: end;
    text-align: start;
}

.contact{
    display: flex;
    flex-direction: column;
    row-gap: 5%;
}
.contactmobile{
    display: none;
    flex-direction: column;
}

.contact p{
    font-family: Raleway;
    font-size: calc(0.7rem + 0.4vw);
font-weight: 400;
letter-spacing: 0em;
}


.contactmobile h2{
    font-family: Raleway;
    font-size: calc(1rem + 0.5vw);
}

.contactmobile p{
    font-family: Raleway;
    font-size: calc(0.7rem + 0.4vw);
font-weight: 400;
letter-spacing: 0em;
}

.socials{
    width: 50%;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    column-gap: 2%;
    margin-top: 3%;
}
.socialsmobile{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 33%);
    column-gap: 0%;
    margin-top: 10%;
}

.bottom{
    position: relative;
    width: 100%;
    max-width: 1900px;
    margin: 0 auto;
    border-top: 2px solid #fff;
    background-color: #1D212D;
    color: #fff;
}

.bottomcontent{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
}

.back{
    width: 100%;
    text-align: end;
    justify-content: end;
    cursor: pointer;
}

.back p:hover{
    color: #EB4138;
    text-decoration: underline #EB4138;
}

.terms{
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 5%;
}

.terms p:hover{
    color: #EB4138;
    text-decoration: underline #EB4138;
}

.termsmobile{
    width: 100%;
    display: none;
    flex-direction: column;
    margin-top: 10%;
}

.copyright{
    width: 100%;
    align-content: end;
    text-align: center;
    justify-self: center;
}

.logomobile{
    display: none;
    width: 70%;
    margin-left: 10%;
}

.options h2:hover{
    color: #EB4138;
    text-decoration: underline #EB4138;
}

.socials img:hover{
    box-shadow: 1px 2px 10px 5px #fff;
    border-radius: 0.3em;
}

.btn{
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    border: 1px solid transparent;
    border-radius: 10px;
    background: #EB4138;
    padding: 0.3em 0em 0.1em 0.5em ;
    color: white;
    justify-self: center;
    text-align: center;
        align-self: center;
        transition: background-color 0.5s ease, color 1s ease;
    font-size: calc(0.7rem + 0.3vw);
  }
  .btn:hover {
    background: transparent;
    color: #EB4138; 
    border: 1px solid #EB4138;
  }

  .subscribe_mob{
    display: none;
    font-size: calc(0.6rem + 0.6vw);
  }

@media screen and (max-width:950px) {
    .desc{
        display: none;
    }
    .back{
        display: none;
    }
    .terms{
        display: none;
    }
    .routing{
        grid-template-columns: repeat(2, 50%);
    }
    .termsmobile{
        display: flex;
    }
    .contact{
        display: none;
    }
    .contactmobile{
        display: flex;
    }
    .options h2{
        text-decoration: underline;
    }
    .logomobile{
        display: flex;
    }

    .subscribe_mob{
        display: flex;
    }
}