.privacy {
    position: relative;
    width: 100%;
    max-width: 1900px;
    height: fit-content;
    justify-content: center;
    align-items: center;
  }
  
  .content {
    width: 88%;
    margin-left: 6%;
  }
  
  .title {
    padding-top: 12%;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    width: 100%;
  }
  
  .title h1 {
    width: fit-content;
    text-decoration: none ;
    font-family: Raleway;
    font-size: calc(1.5rem + 1vw);
    font-weight: 600;
    line-height: 55px;
    letter-spacing: 0em;
    border-bottom: 4px solid #EB4138
  }
  
  .title p {
    text-align: end;
    justify-self: flex-end;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.5vw);
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    color: #EB4138;
    ;
  }
  
  .text {
    width: 100%;
    text-align: start;
    padding-bottom: 8%;
  }
  
  .text h3 {
    padding-top: 3%;
    font-family: Raleway;
    font-size: calc(0.8rem + 0.5vw);
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
  }
  
  .text p{
    font-family: Raleway;
    font-size: calc(0.7rem + 0.5vw);
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
  }
  