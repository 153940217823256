.navbar {
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.navbar_con_before {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  padding: 1% 0;
  background-color: transparent;
  color: white;
}

.navbar_con {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center;
  padding: 1% 0;
  background-color: #ffffff9f;
}

.logo_con{
  width: 28%;
  margin: auto 0;
  justify-content: flex-start;
  text-align: start;
  align-items: center;
  object-fit: contain;
}

.content {
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: row;
  max-width: 1900px;
  margin: 0 auto !important;
}

.options_con {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 4%;
  color: #fff;
  /* background-color: #eb4138; */
}

.options_con_before {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 4%;
  color: #fff;
}

.options_con_before p {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-family: Raleway;
  font-size: calc(0.7rem + 0.5vw);
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
}

.options_con p {
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-decoration: none;
  font-family: Raleway;
  font-size: calc(0.7rem + 0.5vw);
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
}

.options_con p:hover {
  color: #f5f5f5;
  text-decoration: none;
}

.options_con_before p:hover {
  color: #000;
  text-decoration: none;
}

.options_con_before p::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  margin-top: 5px;
  color: #000;
  background-color: #000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.options_con p::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  margin-top: 5px;
  color: #f5f5f5;
  background-color: #f5f5f5;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.options_con_before p:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: #000;
}

.options_con p:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
  color: #f5f5f5;
}

.options_con_before button {
  background: #eb4138;
  border-radius: 10px;
  border: 1px solid #eb4138;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  width: 100%;
  align-self: center;
  padding-top: 10px;
  margin-top: 10px;
  font-family: Raleway;
  font-size: calc(0.6rem + 0.4vw);
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  transition: background-color 0.5s ease, color 1s ease;
}

.options_con button {
  background: #eb4138;
  border-radius: 10px;
  border: 1px solid #eb4138;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  width: 100%;
  align-self: center;
  padding-top: 10px;
  margin-top: 10px;
  font-family: Raleway;
  font-size: calc(0.6rem + 0.4vw);
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  transition: background-color 0.5s ease, color 1s ease;
}

.options_con_before button:hover {
  background: white;
  color: #eb4138;
  border: 1px solid #eb4138;
}

.options_con button:hover {
  background: white;
  color: #eb4138;
}

.links {
  text-decoration: none;
  color: white;
}

.links_before {
  text-decoration: none;
  color: black;
}

.mobileicon{
  display: none;
  width: 100%;
  height: fit-content;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width:950px) {
  .mobileicon{
    display: flex;
  }
  .options_con{
    display: none;
  }
  .options_con_before{
    display: none;
  }
}

@media screen and (max-width:650px) {
  .logo_con{
    width: 60%;
  }
  .logo_con img{
    width: 100%;
  }
}