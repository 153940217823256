.bigContainer{
    position: relative;
    font-family: Raleway;
    display: flex;
    flex-direction: row;
    gap: 2%;
    width: 88%;
    margin-left: 6%;
    padding-bottom: 5%;
    max-width: 1900px;
    margin: 0 auto;
}
.heading{
  display: none;
}
.contactUsCard{
    width: 31%;
    background-image: url('../../../assets/ContactUsBg.png'); 
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    flex: 1;
}
.contactUsCardMob{
  display: none;
}
.contents{
    position: relative;
    width: 80%;
    height: 100%;
    height: fit-content;
    font-family: Raleway;
    letter-spacing: 0em;
    text-align: start;
    color: white;
    font-size: calc(0.5rem + 0.7vw);
    margin: 10%;
}

.bigContainer button{
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    border: 1px solid #EB4138;
    border-radius: 0.5em;
    background: #EB4138;
    color: white;
    padding: 0% 10%;
    line-height: 0;
    justify-self: center;
    text-align: center;
    align-self: center;
    transition: background-color 0.5s ease, color 1s ease;
    margin-top: 5%;
  }

.bigContainer button:hover {
    background: white;
    color: #EB4138;
    cursor: pointer;
  }

.blogCard{
    flex: 1;
    width: 31%;
    background-color: red;
    
    border-radius: 15px;
}


.contact_option_error {
  color: white;
  align-self: flex-start;
  font-size: 16px;
  margin-top: -.5em;
}

  .swiper {
    position: relative;
    width: 67%;
    flex: 1;
    cursor: url(../../../assets/pointer.png), auto;
  }
  
  .cardtext {
    position: relative;
    color: #000;
    height: fit-content;
    text-align: start;
    padding: 0 5%;
    font-family: Raleway;
  }
  
  .cardtext h3 {
    font-family: Raleway;
    font-size: calc(0.5rem + 0.7vw);
    font-weight: 600;
    text-align: start;
  }
  
  .cardtext p{
      font-family: Raleway;
      font-size: calc(0.5rem + 0.7vw);
      font-weight: 500;
      text-align: start;
  }

  .btncon{
    display: grid;
    grid-template-columns: 40% 60%;
    padding-bottom: 5%;
  }
  
  .PrimaryCTAButton {
    position: relative;
    width: 100%;
    text-align: center;
    align-self: center;
    justify-content: center;
  }
  

  .PrimaryCTAButton p{
    height: fit-content;
    align-self: center;
    cursor: pointer;
  }
  .image_height{
    height: 20vw;
  }

  
@media screen and (max-width: 950px) {
  .bigContainer{
    display: flex;
    flex-direction: column;
}
  .heading {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5% 0;
}

.header{
    position: relative;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    font-family: Raleway;
    font-size: calc(0.7rem + 1.2vw);
    font-weight: 600;
    line-height: 0;
    letter-spacing: 0em;
    text-align: center;
    color: #EB4138;
    align-self: center;
    align-items: center;
}
  .subTitle{
    width: 80%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.9vw);
    font-weight: 400;
    letter-spacing: 0em;
    padding-top: 1.5%;
    color: #1D212D;
    padding-left: 5%;
    text-align: center;
    align-self: center;

}
.image_height{
  height: 30vw;
}
.contactUsCard{
  width: 31%;
  background-image: url('../../../assets/ContactUsBg.png'); 
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
}
.contactUsCardMob{
  display: flex;
  width: 100%;
  background-image: url('../../../assets/ContactUsBg.png'); 
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
}
.contactUsCard{
display: none;
}
.swiper {
  position: relative;
  width: 100vw;
  margin-left: -6%;
  cursor:auto;
  font-size: calc(0.5rem + 0.7vw);
  margin-bottom: 10%;
}
}

@media screen and (min-width: 1400px) {
  .contents{
    font-size: calc(1rem + 0.2vw);
  }
  .cardtext h3 {
    font-size: calc(1rem + 0.2vw);
  }

  .cardtext p{
      font-size: calc(1rem + 0.2vw);
  }
  .header{
    font-size: calc(1.5rem + 0.3vw);
  }
  .subTitle{
    font-size: calc(1.3rem + 0.2vw);
  }
}
@media screen and (max-width:650px) {

  .image_height{
    height: 40vw;
  }
  
}


@media screen and (max-width:300px) {
.btncon{
  grid-template-columns: 1fr;
}
}