.career{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    background-color: #eeee;
  }
  
  .videoContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
  
  .video{
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .CatchyHeadline {
    position: relative;
    width: 100%;
    height: fit-content;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 15em 0 10em 0;
  }
  
  .taglineCont {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;grid-template-columns: repeat(2, 50%);
    color: white;
    justify-content: center;
    align-items: center;
    max-width: 1900px;
    margin: 0 auto;
  }

  .Tagline {
    position: relative;
    width: 90%;
    margin-left: 10%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(2rem + 1vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: start;
    z-index: 2;
    margin-right: 20%;
  }
  
  .description{
    z-index: 2;
    width: 90%;
    font-size: calc(0.9rem + 0.4vw);
    text-align: start;
  }
  
  .career_con{
    position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center; 
  }
  
  .content{
    width: 80%;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: start;
    padding-top: 6%;
    max-width: 1900px;
    margin: 0 auto;
  }

  .header{
    width: 100%;
    display: grid;
    grid-template-columns: 46% 50%;
    column-gap: 4%;
  }

  .header h2{
    position: relative;
    width: 100%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(2rem + 1vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: start;
    margin-right: 20%;
  }

  .header p{
    width: 100%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.9rem + 0.4vw);
    font-weight: 300;
    letter-spacing: 0em;
    margin: auto ;
  }
  
  .table{
    position:relative;
    display: grid;
    grid-template-columns: 30% 65%;
    column-gap: 5%;
    margin: 8% 0 5% 0;
  }

  .sidebar{
    display: flex;
    flex-direction: column;
    row-gap: 0rem;
    margin-bottom: 20rem;
  }

  .p{
    font-family: Raleway;
font-size: calc(1rem + 0.6vw);
font-weight: 600;
letter-spacing: 0em;
text-align: start;
color: #1D212D;
cursor: pointer;
  }
  
  .selectedp{
    font-family: Raleway;
font-size: calc(1rem + 0.6vw);
font-weight: 600;
letter-spacing: 0em;
text-align: start;
color: #EB4138;
cursor: pointer;
  }

  .card{
    background:#FFFFFF;
    border: none;
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 0 2% ;
    margin-bottom: 2%;
  }

  .carddesktoprow{
    display: grid;grid-template-columns: repeat(3, 33%);
    justify-content: space-between;
  }

  
  .card h4{
    position: relative;
    width: 100%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1rem + 0.4vw);
    font-weight: 600;
    margin: auto;
    letter-spacing: 0em;
    text-align: center;
  }

  .column p{
    text-align: center;
    font-family: Raleway;
font-size: calc(0.7rem + 0.4vw);
font-weight: 400;
letter-spacing: 0em;
text-align: center;

  }

  .mobilebtns{
    display: none;
    grid-template-columns: 45% 45%;
    column-gap: 1rem;
    row-gap: 1rem;
    height: fit-content;
  }

  
  .btn{
    font-family: Raleway;
    font-size: calc(0.7rem + 0.3vw);
font-weight: 600;
letter-spacing: 0em;
text-align: start;
color: #1D212D;
cursor: pointer;
border: 1px solid transparent;
border-radius: 10px;
background-color: #fff;
padding: 10% 2%;
  }
  
  .selectedbtn{
    font-family: Raleway;
font-size: calc(0.7rem + 0.3vw);
font-weight: 600;
letter-spacing: 0em;
text-align: start;
color: #fff;
background-color: #EB4138;
cursor: pointer;
border: 1px solid #EB4138;
padding: 10% 2%;
  }

  .cardmobile{
    background:#FFFFFF;
    border: none;
    border-radius: 10px;
    display: none;
    flex-direction: column;
    padding: 0 2% ;
    margin-bottom: 4%;
  }

  .cardrow{
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .cardrow1{
    display: flex;
  }

  .cardrow1 h4{
    position: relative;
    width: 100%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1rem + 0.4vw);
    font-weight: 600;
    margin: auto;
    letter-spacing: 0em;
    text-align: center;
    padding: 2% 0 0 0 ;
  }
  
  .cardrow h4{
    position: relative;
    width: 100%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1rem + 0.4vw);
    font-weight: 600;
    margin: auto;
    letter-spacing: 0em;
    text-align: center;
  }

@media screen and (max-width:950px) {
  .CatchyHeadline {
    grid-template-columns: 1fr;
  }

  .taglineCont{
    grid-template-columns: 1fr;
  }

  .Tagline {
    
    font-size: calc(1rem + 2vw);
    width: 80%;
    margin-right: 0%;
  }
  
  .description{
    width: 80%;
    margin-left: 10%;
  }
  .content{
    grid-template-columns: 1fr;
  }

  .header{
    grid-template-columns: 1fr;
  }
  
  .header h2{
    font-size:  calc(1rem + 1vw);
  }

  .sidebar{
    margin-bottom: 5rem;
  }

  .sidebar p{
    display: none;
  }

  .table{
    grid-template-columns: 100%;
    row-gap: 0;
  }

  .mobilebtns{
    display: grid;
  }
  .card{
    display: none;
  }

  .cardmobile{
    display: flex;
  }
}