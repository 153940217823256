.team {
  position: relative;
  width: 100%;
  margin: 0 auto;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  color: #fff;
  height: fit-content;
}

.team_con {
  position: relative;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center; 
}

.headerCont {
  width: 100%;
  padding: 3% 0;
  background: #1d212d;
  color: #fff;
}
.header {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 50%);
  text-align: center;
  align-items: center;
  color: #fff;
  max-width: 1900px;
  margin: 0 auto;
}

.header h3 {
  font-family: Raleway;
  font-size: calc(1.5rem + 1.5vw);
  font-weight: 600;
  letter-spacing: 0em;
}

.header p {
  width: 95%;
  text-align: start;
  font-family: Raleway;
  font-size: calc(0.8rem + 0.5vw);
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

}

.rows{
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin: auto 0; */
  height: fit-content;
  padding-top: 3%;
  overflow: hidden;
}
.row1{
  width: 100%;
  column-gap: 2%;
  display: grid;
  /* background-color: #000; */
  grid-template-columns: 20% 20% 20% 20%;
  padding: 0 0 0 7%;
}
.row{
  width: 100%;
  column-gap: 2%;
  display: grid;
  grid-template-columns: 20% 20% 20%;
  margin: 0 17%;
}

.teamImage {
  width: 100%;
  height:25vw !important;
  border-radius: 1.3em;
  object-fit: cover;
  object-position: top;
}

.swiper {
  padding-top: 3em;
  display: none;
  position: relative;
  /* cursor: url(../../../assets/pointer.png), auto; */
}

.cardtext {
  position: relative;
  color: #000;
  text-align: start;
  padding-left: 5%;
  margin: 1em 0;
}

.cardtext h3 {
  font-family: Raleway;
  font-size: calc(0.8rem + 0.5vw);
  font-weight: 600;
  letter-spacing: 0em;
  text-align: start;
  margin: 0;
}

.cardtext p{
    font-family: Raleway;
    font-size: calc(0.7rem + 0.5vw);
    font-weight: 500;
    letter-spacing: 0em;
    text-align: start;
    margin: 2% 0;
}
.teammembers{
  width: 100%;
  height: fit-content;
  position: relative;
  max-width: 1900px;
  margin: 0 auto;
  /* background-color: #000; */
}

@media screen and (max-width: 950px) {
  .swiper{
    display: block;
  }
  .teammembers{
    display: none;
  }
  .header{
    grid-template-columns: 1fr;
  }
  
  .header p {
  text-align: center;
  margin: 0 auto; 
}
.teamImage {
  height:45vw !important;
}
}
@media screen and (max-width:650px) {
  .swiper{
    display: block;
    width: 100vw;
    margin-left: 0%;
  }
  .teamImage {
    height:75vw !important;
  }
  
}

 @media screen and (min-width: 1900px) {
  .teamImage {
    height:30em !important;
  }
} 