.productdesign{
    width: 100%;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    background-color: #eeee;
  }
  
  .videoContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
  
  .video{
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .CatchyHeadline {
    position: relative;
    width: 100%;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 15em 0 15em 0;
  }

  .taglineCont {
    position: relative;
    width: 100%;
    height: fit-content;
    display: grid;grid-template-columns: repeat(2, 50%);
    color: white;
    justify-content: center;
    align-items: center;
    max-width: 1900px;
    margin: 0 auto;
  }
  
  .Tagline {
    position: relative;
    width: 90%;
    margin-left: 10%;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(1.5rem + 1vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: start;
    margin-right: 20%;
    z-index: 2;
  }
  
  .description{
    text-align: start; 
    width: 90%;
    /* margin-left: 10%; */
    text-align: start;
    z-index: 2;
  }
  
  .productdesign_con{
    position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center; 
  }
  .bigContainer{
    position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center; 
    max-width: 1900px;
    margin: 0 auto;
  }

  .content{
    width: 80%;
    margin-left: 10%;
    position: relative;
    display: grid;
    grid-template-columns: 60% 35%;
    column-gap: 5%;
    text-align: start;
    padding-top: 6%;
  }
  
.title {
    align-items: center;
    width: 100%;
  }
  
  .title h1 {
    width: fit-content;
    text-decoration: none ;
    font-family: Raleway;
    font-size: calc(1.5rem + 1.5vw);
    font-weight: 600;
    letter-spacing: 0em;
    border-bottom: 4px solid #EB4138
  }

  .paragraph{
    padding: 5% 0;
    font-family: Raleway;
letter-spacing: 0em;
  }

  .point{
    width: 10px;
    height: 10px;
    /* border-radius: 50%; */
    color:#001833;
  }

  .paragraph h3{
    font-size: calc(0.8rem + 0.5vw);
    font-weight: 600;
  }

.jobsummary{
    position: relative;
    height: fit-content;
    background:#fff ;
    padding-left: 5%;
    border-radius: 20px;
    padding-top: 8%;
}

.jobsummary h3{
  font-family: Raleway;
font-size: calc(0.8rem + 0.4vw);
font-weight: 600;
letter-spacing: 0em;
margin-left: 5%;
}

.row{
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    font-family: Raleway;
font-size: 18px;
font-weight: 400;
letter-spacing: 0em;

}


.row h4{
    font-family: Raleway;
font-weight: 400;
letter-spacing: 0em;
color: #EB4138,
}

.row p{
font-family: Rubik;
font-size: 18px;
font-weight: 400;
letter-spacing: 0em;
}

.shareit{
  padding-bottom: 5%;
}

.shareit p{
  padding-top: 5%;
  font-family: Raleway;
font-size: 18px;
font-weight: 400;
letter-spacing: 0em;
color: #001833;
}

.btns{
  width: 50%;
  display: flex ;
  flex-direction: column;
  row-gap: 1rem;
}

.applybtn{
  background: #EB4138;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  width: 100%;
  justify-self: flex-start;
  padding: 10px 30px 8px 30px;
  margin-top: 10px;
  font-size: calc(0.7rem + 0.2vw);
  transition: background-color 0.5s ease, color 1s ease;
  margin-bottom: 5%;
  margin-left: 0 !important;
}

.applybtn:hover{
  background: white;
  color: #EB4138;
  border: 1px solid #EB4138;
}

.linkedinbtn{
  background: rgb(100, 88, 176);
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  width: 100%;
  justify-self: flex-start;
  padding: 10px 30px 8px 30px;
  margin-top: 10px;
  font-size: calc(0.7rem + 0.2vw);
  transition: background-color 0.5s ease, color 1s ease;
  margin-bottom: 5%;
  margin-left: 0 !important;
}

.linkedinbtn:hover{
  background: white;
  color: rgb(100, 88, 176);
  border: 1px solid rgb(100, 88, 176);
}

@media screen and (max-width:950px) {
  .CatchyHeadline {
    grid-template-columns: 1fr;
  }
  
  .taglineCont{
    grid-template-columns: 1fr;
  }
  
  .content{
    grid-template-columns: 1fr;
  }
  .Tagline {
    width: 80%;
    margin-right: 0%;
  }
  
  .description{
    width: 80%;
    margin-left: 10%;
  }
  .btns{
    width: fit-content;
  }
}