.briefintro{
    width: 100%;
    position: relative;
    height: fit-content;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
  }

  .videoContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
  }
  
  .video{
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  .CatchyHeadline {
    position: relative;
    width: 100%;
    /* margin-left: 10%; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 15em 0 15em 0;
  }
  
  .Tagline {
    position: relative;
    width: 80%;
    margin: 0 auto;
    height: fit-content;
    font-family: Raleway;
    font-size: calc(2rem + 1vw);
    font-weight: 600;
    text-align: center;
    z-index: 2;
    max-width: 1900px;
    margin: 0 auto;
  }
  
  .description{
    text-align: center;
    width: 60%;
    margin: 0 auto;
    z-index: 2;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .briefintro_con{
    position: relative;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center; 
  }

.blogintro_container{
    width: 100%;
    height: fit-content;
    justify-content: center;
    text-align: center;
    align-items: center;
    padding: 5em 0 0 0;
    max-width: 1900px;
    margin: 0 auto;
}

.header{
    width: 88%;
    margin-left: 6%;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 1em;
    color: #000;
font-family: Metropolis;
font-style: normal;
    font-size: calc(0.5rem + 1.5vw);
}

.header h1{
    font-weight: 800;
    text-shadow: 2px 2px 20px rgba(18, 29, 133, 0.32), 2px 2px 20px rgba(18, 29, 133, 0.32);
    padding-bottom: 3em;
}

.header p{
    font-size: calc(0.9rem + 0.2vw);
    font-weight: 400;
    padding-bottom: 3em;
}

.image{
    width: 90%;
    height: 30vw;
    object-fit: cover;
}

.body{
    width: 88%;
    margin: 0 auto;
    text-align: start;
    color: #000;
font-family: Metropolis;
font-style: normal;
}


.body h2{
    font-size: calc(0.9rem + 0.3vw);
    font-weight: 700;
    padding-top: 4vh;
}

.body p{
    font-size: calc(0.9rem + 0.2vw);
    font-weight: 400;
    margin-top: 4vh;
}

@media screen and (max-width: 950px){
.description{
    width: 80%;
    text-align: start;
}

.image{
    height: fit-content;
}
.bubbel{
    margin-left: 30%;
    margin-top: 40%;
    width: 70%;
}
.Tagline{
  text-align: start;
}
  }


@media screen and (max-width:650px) {
    .Tagline{
      font-size: calc(1rem + 1vw);
    }
}