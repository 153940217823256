.HeroContainer{
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire div */
  }

  .CatchyHeadline {
    position: relative;
    width: 90%;
    height: fit-content;
    display: flex;
    flex-direction: row;
    padding: 25em 0 15em 10vw;
    color: white;
    justify-content: center;
    max-width: 1900px;
    margin: 0 auto;
  }

  .Tagline {
    position: relative;
    width: 50%;
    height: 100%;
    font-family: Raleway;
    font-size: calc(1.5rem + 1.5vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: start;
  }

  .PrimaryCTAButton {
    position: relative;
    width: 50%;
    text-align: start;
    align-self: center;
    padding-left: 15%;
    height: fit-content;
  }

  .PrimaryCTAButton button{
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    border: none;
    border-radius: 0.5em;
    background: #EB4138;
    color: white;
    padding: 0% 5%;
    justify-self: center;
    text-align: center;
    align-self: center;
    transition: background-color 0.5s ease, color 1s ease;
    font-size: calc(0.5rem + 0.7vw);
  }
  .PrimaryCTAButton button:hover {
    background: white;
    color: #EB4138;
    
  }

  @media screen and (max-width: 650px) {
    .HeroContainer{
      position: relative;
      width: 100%;
      height: 75vh;
      overflow: hidden;
  }
    .CatchyHeadline {
      display: flex;
      flex-direction: column;
      padding: 30vh 0 0 10vw;
    }
    .Tagline {
      width: 90%;
      height: fit-content;
      margin: 0;
    }
    h2{
      margin: 0;
    }
    .PrimaryCTAButton {
      width: 100%;
      height: fit-content;
      padding-left: 0%;
      padding-top: 6%;
      margin: 0%;

    }
  }

  @media screen and (min-width: 1400px) {
    .Tagline {
      font-size: calc(2rem + 0.5vw);
    }
    .PrimaryCTAButton button{
      font-size: calc(1rem + 0.2vw);
    }
  }