.ProcessContainer {
    position: relative;
    width: 88%;
    height: fit-content;
    margin-left: 6%;
    display: flex;
    flex-direction: column;
    padding-bottom: 5%;
    max-width: 1900px;
    margin: 0 auto;
    overflow: hidden;
}

.heading {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0% 0 4% 0;
    /* background-color: red; */
}

.header{
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: row;
    font-family: Raleway;
    font-size: calc(0.7rem + 1.2vw);
    font-weight: 600;
    letter-spacing: 0em;
    text-align: center;
    color: #1D212D;
    padding-left: 5%;
    
}
.subTitle{
    width: 50%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.9vw);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 1.5%;
    color: #1D212D;
}

.contantContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
}

.contant{
    position: relative;
    display: flex;
    flex-direction: column ;
    width: 88%;
    margin-left: 10%;
    /* background-color: #1D212D; */
    height: fit-content;
    text-align: center;
    align-items: center;
    margin-bottom: 10%;
}

.title{
    font-family: Raleway;
    transform: scale(0.5); 
    transform-origin: top center; 
    transition: transform 0.5s ease;
}

.hoveredTitle {
    font-family: Raleway;
    transform: scale(1); 
    transform-origin: top center; 
    transition: transform 0.5s ease;
  } 

.discription{
    font-family: Raleway;
    transition-delay: 3s;
    transform: scale(0);
    transform-origin: top center;
    transition: transform 0.5s ease; 
}
 .hoveredDiscription{
    font-family: Raleway;
    transition-delay: 3s;
    transform: scale(1);
    transform-origin: top center;
    transition: transform 0.5s ease; 
  }
  
.box{
    position: relative;
    width: 3em;
    height: 3em;
    border-radius: 5px;
    background-color: #1D212D;
    margin: 15% 0;
   z-index: 99;
}

.box1{
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #1D212D;
    z-index: 22;
}
.line{
    position: absolute;
    width: 80vw;
    margin-left: -6vw;
    height: 0.5em;
    background-color: #1D212D;
    top: 40%;
    z-index: 1;
}


.swiper{
    display: none;
}

@media screen and (max-width: 650px) {
    .ProcessContainer {
        position: relative;
        width: 88%;
        height: fit-content;
        margin-left: 6%;
        display: flex;
        flex-direction: column;
        padding: 10% 0 0 0;
    }
    .heading {
        display: flex;
        flex-direction: column;
    }
    
.header{
    width: fit-content;
    align-self: center;
    text-align: center;
    align-items: center;
    padding-left: 0%;
    color: #EB4138;
}
.subTitle{
    width: 80%;
    align-self: center;
    text-align: center;
}
}
@media screen and (max-width: 950px) {
    .subTitle{
        padding-top: 0.5em;
    }
.contantContainer{
    display: none;
}

.swiper{
    position: relative;
    display: flex;
    flex-direction: row;
    /* margin-top: 5em; */
    justify-content: center;
    text-align: center;
    align-items: center;
}
.line{
    width: 44.1vw;
    margin-left: -18.7vw;
    height: 0.5em;
    background-color: #1D212D;
    top: 40%;
    z-index: 1;
}

.contant{
    position: relative;
    display: flex;
    flex-direction: column ;
    width: 80%;
    margin-left: 0 auto;
    height: 100%;
    align-self: flex-start;
    justify-self: start;
    font-size: calc(0.7rem + 0.9vw);
    font-family: Raleway;
}
.box{
    position: relative;
    width: 3em;
    height: 3em;
    border-radius: 5px;
    background-color: transparent;
    margin: 15% 0;
    z-index: 99;
}
}

@media screen and (min-width: 1100px) {
    .header{
        font-size: calc(1.5rem + 0.3vw);
    }
    .subTitle{
        font-size: calc(1.3rem + 0.2vw);
    }
    .contant{
        font-size: calc(1rem + 0.2vw);
    }
}
