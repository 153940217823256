.PortfolioContainer {
    position: relative;
    width: 88%;
    margin-left: 6%;
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
    max-width: 1900px;
    margin: 0 auto;
    
}

.heading {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5% 0;
}

.header{
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: row;
    font-family: Raleway;
    font-size: calc(0.7rem + 1.2vw);
    font-weight: 600;
    line-height: 0;
    letter-spacing: 0em;
    text-align: center;
    color: white;
    padding-left: 5%;
    padding-top: 1.5%;
}
.subTitle{
    width: 50%;
    text-align: start;
    font-family: Raleway;
    font-size: calc(0.7rem + 0.9vw);
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 1.5%;
    color: white;
}

.contantContainer{
    width: 100%;
    display: grid;
    grid-template-columns: 48.25% 48.25%;
    gap: 3.5em;
}

.PortfolioCard{
    position: relative;
    width: 100%;
    background-color: #1D2028;
    border-radius: 15px;
}

.contant{
    width: 80%;
    margin: 5% auto;
    display: grid;
    grid-template-columns: 40% 60%;
    font-size: calc(0.5rem + 0.7vw);
    font-family: Raleway;
    /* background-color: red; */
}

.PrimaryCTAButton {
    position: relative;
    width: 100%;
    text-align: center;
    align-self: center;
  }

  .PortfolioContainer button{
    position: relative;
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: fit-content;
    border: none;
    border-radius: 0.5em;
    background: #EB4138;
    color: white;
    padding: 0% 5%;
    justify-self: center;
    text-align: center;
    align-self: center;
    transition: background-color 0.5s ease, color 1s ease;
    font-size: calc(0.4rem + 0.6vw);
    margin-top: 5%;
  }
  .PortfolioContainer button:hover {
    background: white;
    color: #EB4138;
    
  }

@media screen and (max-width: 650px) {
    .PortfolioContainer {
        padding-bottom: 10%;
        
    }
    .heading {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 5% 0;
    }
.header{
    width: fit-content;
    align-self: center;
    text-align: center;
    align-items: center;
    padding-left: 0%;
    color: #EB4138;
}
.subTitle{
    width: 80%;
    align-self: center;
    text-align: center;
}
   
.contantContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3.5vw;
}
.PortfolioCard{
    position: relative;
    width: 100%;
    height: fit-content;
    background-color: #1D2028;
    border-radius: 15px;
}
.contant{
    width: 80%;
    height: fit-content;
    margin: 5vh auto;
    display: grid;
    grid-template-columns: 40% 60%;
    font-size: calc(0.5rem + 0.7vw);
    font-family: Raleway;
    /* background-color: red; */
}
}

@media screen and (min-width: 1400px) {
    .header{
        font-size: calc(1.5rem + 0.3vw);
    }
    .subTitle{
        font-size: calc(1.3rem + 0.2vw);
    }
    .contant{
        font-size: calc(1rem + 0.2vw);
    }
    .PortfolioContainer button{
        font-size: calc(0.8rem + 0.2vw);
      }
}