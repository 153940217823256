.briefintro{
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}

.videoContainer{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}

.video{
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.CatchyHeadline {
  position: relative;
  width: 100%;
  height: fit-content;
  color: white;
  justify-content: center;
  align-items: center;
  padding: 15em 0 15em 0;
}
  
.taglineCont {
  position: relative;
  width: 100%;
  height: fit-content;
  display: grid;grid-template-columns: repeat(2, 50%);
  color: white;
  justify-content: center;
  align-items: center;
  max-width: 1900px;
  margin: 0 auto;
}

.Tagline {
  position: relative;
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  font-family: Raleway;
  font-size: calc(0.7rem + 1.2vw);
  font-weight: 600;
  letter-spacing: 0em;
  text-align: start;
  z-index: 2;
}

.description{
  width: 80%;
  margin: 0 auto;
  z-index: 2;
  text-align: start;
}

.briefintro_con{
  position: relative;
  width: 100%;
  height: fit-content;
  justify-content: center;
  align-items: center; 
}
.devscroll_con {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  overflow: hidden;
}

.dev_content {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: start;
  font-family: Metropolis;
  font-size: calc(0.5rem + 0.7vw);
  font-weight: 400;
  letter-spacing: 0em;
}

.image_con {
  /* background-color: blueviolet; */
  justify-content: center;
}

.description_con {
  width: fit-content;
  height: 30vh;
  margin-left: 10%;
  font-family: Metropolis;
  font-size: calc(0.5rem + 0.7vw);
  font-weight: 400;
  letter-spacing: 0em;
  justify-content: center;
  align-self: center;
}

.service {
  /* padding: 5vh 0 5vh 0; */
  width: fit-content;
  height: fit-content;
  text-align: start;
}


.panel {
    width: 100%;
    position: absolute;
    text-align: center;
    overflow: hidden;
}

.panelspan {
  position: relative;
  width: 70%;
  margin-left: 15%;
  display: block;
  top: 50%;
  padding-top: 20%;
  color: #000;

}
.imgCont {
  position: relative;
  display: block;
  padding-top: 10%;
}
.imgContmobile {
  position: relative;
  display: flex;
  padding-top: 10%;
}

.description {
  font-family: Raleway;
  font-size: calc(0.5rem + 0.7vw);
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

}


.pinContainerpanel {
  height: 30vh;
  width: 100%;
  position: absolute;
  text-align: center;
}

.testt {
  width: 100%;
  height: fit-content;
  /* background: linear-gradient(147.42deg, #121D85 3.51%, #000000 192.93%); */

}

.mobile{
  width: 100%;
  display: none;
  flex-direction: column;
  height: fit-content;
}

.mobiletext{
  width: 80%;
  margin-left: 10%;
  height: fit-content;
  justify-content: center;
  color: #000;
  text-align: start; 
  padding-bottom: 10%;
  padding-top: 1%;
  font-size: calc(0.5rem + 0.7vw);
}

@media screen and (max-width: 950px) {
  .devservices_con{
    display: none;
  }

  .devscroll_con{
    display: none;
  }
  
.mobile{
  display: flex;
}
.CatchyHeadline{
  /* padding: 5vh 0; */
  top: 50%;
  grid-template-columns: 1fr;
}
}
@media screen and (max-width:650px) {
.Tagline {
  font-size: calc(1.5rem + 0.2vw);
}
 
.taglineCont{
  grid-template-columns: 1fr;
}
.dev_content {
  font-size: calc(1rem + 0.2vw);
}
.description_con {
  font-size: calc(1rem + 0.2vw);
}
.description {
  font-size: calc(1rem + 0.2vw);
}
.mobiletext{
  font-size: calc(1rem + 0.2vw);
}
}